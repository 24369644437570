.entrada-blog{
    margin-bottom: 2rem;
    @include tablet{
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 8rem;
    }
    &:last-of-type{
        margin-bottom: 0;
    }
}
.texto-entrada{
    a{
        color: $negro;
        display: block;
        margin-top: 2rem;
        @include tablet{
            margin-top: 0;
        }
    }
    h4{
        margin: 0;
        line-height: 1.4;
        &::after{
            content: '';
            display: block;
            width: 15rem;
            height: .5rem;
            background-color: $verdePrincipal;
        }
    }
    span{
        color: $amarillo;
    }
}