@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/** Media Queries **/
html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
}

p {
  color: #000000;
  font-size: 2rem;
}

.contenedor {
  width: 95%;
  max-width: 120rem;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

img,
picture {
  width: 100%;
  display: block;
}

h1, h2, h3 {
  font-family: "Lato", sans-serif;
  margin: 2.5rem 0;
  font-weight: 300;
  text-align: center;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 3.4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.6rem;
}

h5 {
  font-size: 1.8rem;
}

.seccion {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.contenido-centrado {
  max-width: 80rem;
}

.alinear-derecha {
  display: flex;
  justify-content: flex-end;
}

.boton-verde {
  background-color: #3D8C03;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  margin-bottom: 2.5rem;
  border: none;
}
.boton-verde:hover {
  background-color: #327302;
  cursor: pointer;
}

.boton-verde-block {
  background-color: #3D8C03;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  margin-bottom: 2.5rem;
  border: none;
}
.boton-verde-block:hover {
  background-color: #327302;
  cursor: pointer;
}

.boton-gris {
  background-color: #e1e1e1;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  margin-bottom: 2.5rem;
  border: none;
}
.boton-gris:hover {
  background-color: #d4d4d4;
  cursor: pointer;
}

.dark-mode {
  background-color: #1a1a1a;
}
.dark-mode p {
  color: #FFFFFF;
}
.dark-mode a, .dark-mode h1, .dark-mode h2, .dark-mode h3, .dark-mode h4, .dark-mode h5 {
  color: #e1e1e1;
}
.dark-mode .servicio {
  background-color: #333333;
  border: 1px solid #333333;
}
.dark-mode .contenido-servicio {
  background-color: #333333;
  border: 0;
  color: #FFFFFF;
}
.dark-mode .servicio-detalle {
  background-color: #333333;
}
.dark-mode .btn-cerrar {
  color: #000000;
}
.dark-mode .galeria-imagenes picture {
  border: 1px solid #FFFFFF;
}
.dark-mode .texto-nosotros {
  color: #FFFFFF;
}
.dark-mode .formulario {
  color: #FFFFFF;
}
.dark-mode .formulario legend {
  color: #FFFFFF;
}

/*
.no-webp .inicio {
    background-image: url(../img/header3.jpg);
}
.webp .inicio{
    background-image: url(../img/header3.webp);
}*/
.header {
  background-color: #3D8C03;
  padding: 0rem 0 0rem 0;
  z-index: 100;
  position: fixed;
  width: 100%;
}
.header.inicio {
  background-position: center center;
  background-size: cover;
}

/*Slider*/
.slider {
  position: relative;
  width: 100%;
  height: 765px;
  padding: 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .slider {
    height: 800px;
  }
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slider-foto li {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: inherit;
  transition: opacity 0.5s;
  color: #FFFFFF;
}
.slider-foto li img {
  width: 100%;
  height: 800px;
  object-fit: cover;
}
.slider-foto li .imagen-slider0 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../img/slider7.jpg);
  width: 100%;
  height: 800px;
  background-position: center center;
  background-size: cover;
}
.slider-foto li .imagen-slider1 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../img/slider1.jpg);
  width: 100%;
  height: 800px;
  background-position: center center;
  background-size: cover;
}
.slider-foto li .imagen-slider2 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../img/slider2.jpg);
  width: 100%;
  height: 800px;
  background-position: center center;
  background-size: cover;
}
.slider-foto li .imagen-slider3 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../img/slider3.jpg);
  width: 100%;
  height: 800px;
  background-position: center center;
  background-size: cover;
}
.slider-foto li .imagen-slider4 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../img/slider4.jpg);
  width: 100%;
  height: 800px;
  background-position: center center;
  background-size: cover;
}
.slider-foto li .imagen-slider5 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../img/slider5.jpg);
  width: 100%;
  height: 800px;
  background-position: center center;
  background-size: cover;
}
.slider-foto li .imagen-slider6 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../img/slider6.jpg);
  width: 100%;
  height: 800px;
  background-position: center center;
  background-size: cover;
}
.slider-foto li .texto-slider {
  line-height: 5rem;
  padding-top: 40rem;
}
@media (min-width: 768px) {
  .slider-foto li .texto-slider {
    line-height: 10rem;
    padding-top: 35rem;
  }
}
.slider-foto li .texto-slider h2 {
  font-size: 3rem;
  font-weight: 400;
}
.slider-foto li .texto-slider p {
  color: #FFFFFF;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .slider-foto li .texto-slider h2 {
    font-size: 4rem;
    color: #FFFFFF;
    font-weight: 900;
    margin: 0;
    padding: 0;
  }
  .slider-foto li .texto-slider p {
    font-size: 2.5rem;
    color: #FFFFFF;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
}

.slider-foto li:first-child {
  opacity: 1;
}

.slider-foto li:target {
  opacity: 1;
}

.slider-menu {
  text-align: center;
  margin-top: 700px;
  display: flex;
  justify-content: center;
}

.slider-menu li {
  position: relative;
  display: inline-block;
  text-align: center;
}

.slider-menu a {
  display: inline-block;
  color: #3D8C03;
  text-decoration: none;
  background-color: #3D8C03;
  padding: 20px;
  width: 10px;
  height: 10px;
  font-size: 1rem;
  border-radius: 100%;
}

/*fin de slider*/
/*
.contenido{
    display: flex;
    flex-direction: column;

    @include tablet{
        min-height: 70rem;
    }
    h1{
        color: $blanco;
        font-weight: $bold;
        line-height: 1.5;
        text-align: center;
        @include tablet{
            padding-top: 20rem;
            text-align:left;
            color: $blanco;
            font-weight: $bold;
            line-height: 1.5;
            max-width: 40rem;
        }
    }  
    p{
        text-align: center;
        color: $blanco;
        font-weight: $bold;
        line-height: 1.5;
        @include tablet{
            max-width: 50rem;
            text-align:left;  

        }

    }
}
*/
.cotizador {
  background-color: #3D8C03;
  text-align: center;
}
.cotizador h4 {
  color: #FFFFFF;
  text-align: center;
}
@media (min-width: 768px) {
  .cotizador {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #3D8C03;
  }
  .cotizador h4 {
    font-size: 2.2rem;
    color: #FFFFFF;
  }
}
.cotizador .boton-gris h4 {
  color: #000000;
  margin: 0;
}

.footer {
  padding: 2.5rem 0;
  background-color: #000000;
  padding-bottom: 0;
  text-align: center;
}

.contenedor-footer {
  padding-top: 2rem;
  padding-bottom: 5rem;
  display: grid;
  flex-direction: column;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .contenedor-footer {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(5, 1fr);
  }
}
.contenedor-footer .sedes {
  grid-row: 1/2;
  grid-column: 2/4;
}
.contenedor-footer .icono {
  padding-top: 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-top: 2rem;
}
.contenedor-footer h5 {
  color: #FFFFFF;
  text-align: left;
  margin-bottom: 0;
}
.contenedor-footer p {
  color: #FFFFFF;
  padding-top: 0;
  margin-top: 0;
  font-size: 1.5rem;
  text-align: left;
}
.contenedor-footer img {
  padding-top: 3rem;
}

.copyright {
  padding: 2.5rem 0;
  color: #FFFFFF;
  text-align: center;
  background-color: #3D8C03;
}

.derecha {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .derecha {
    align-items: end;
  }
}
.derecha .dark-mode-boton {
  width: 3rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  filter: invert(100%);
}
.derecha .dark-mode-boton:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .derecha .dark-mode-boton {
    margin-bottom: 0;
  }
}

.barra {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  align-items: center;
  background-color: #3D8C03;
}
@media (min-width: 768px) {
  .barra {
    padding-top: 0;
    padding-bottom: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
  }
}

.navegacion {
  display: none;
  transition: visibility 3s ease-in-out;
}
@media (min-width: 768px) {
  .navegacion {
    display: flex;
    align-items: center;
  }
}
.navegacion a {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 2.2rem;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .navegacion a {
    margin-right: 2rem;
    font-size: 1.8rem;
  }
  .navegacion a:last-of-type {
    margin-right: 0;
  }
}

/*.mobile-menu{
    @include tablet{
        display: none;
    }
}*/
@media (min-width: 768px) {
  .mobileMenu {
    display: none;
  }
}
.mobileMenu img {
  width: 5rem;
}

.mostrar {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .mostrar {
    flex-direction: row;
  }
}

.header.nosotros {
  width: 100%;
  position: relative;
  z-index: 100;
}

@media (min-width: 768px) {
  .icono-nosotros {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
  }
}
.icono {
  text-align: center;
}
.icono h3 {
  text-transform: uppercase;
}

.icono-mision {
  text-align: center;
}
.icono-mision p {
  text-align: justify;
}
@media (min-width: 768px) {
  .icono-mision h4 {
    text-align: center;
  }
  .icono-mision p {
    text-align: justify;
  }
  .icono-mision svg {
    text-align: center;
  }
}

.contenido-nosotros {
  text-align: left;
}
@media (min-width: 768px) {
  .contenido-nosotros {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.texto-nosotros blockquote {
  font-weight: 900;
  font-size: 2.2rem;
  margin: 3rem 0 0 0;
  padding-left: 1rem 0 3rem 0;
}

.dark-mode blockquote {
  color: #FFFFFF;
}

.texto-nosotros p {
  text-align: justify;
}

.contenido-politicas li:before {
  content: "- ";
}

.contenido-politicas li {
  text-indent: -10px;
  font-size: 2rem;
  text-align: justify;
}

@media (min-width: 768px) {
  .contenedor-servicios {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
  }
}
.servicio {
  background-color: #eeeeee;
  border: 1px solid #e1e1e1;
}
.servicio .contenido-servicio {
  padding: 2rem;
  text-align: center;
}
@media (min-width: 768px) {
  .servicio .contenido-servicio {
    text-align: center;
  }
}
.servicio .contenido-servicio h5, .servicio .contenido-servicio p {
  margin: 0;
}
.servicio .contenido-servicio .amb {
  margin: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .servicio .contenido-servicio .amb {
    margin: 0;
    max-width: 10rem;
    line-height: 3.3rem;
    text-align: center;
  }
}

.servicio-detalle {
  text-align: left;
  background-color: #eeeeee;
  border: 1px solid #e1e1e1;
}
.servicio-detalle .contenido-servicio {
  padding: 2rem;
}
.servicio-detalle .contenido-servicio li:before {
  content: "- ";
}
.servicio-detalle .contenido-servicio li {
  text-indent: -10px;
}

.seccion h2 {
  font-size: 4rem;
  font-weight: 900;
  color: #3D8C03;
}

.no-webp .imagen-cotizar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../img/image247.jpg");
}

.webp .imagen-cotizar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../img/image247.webp");
}

.imagen-cotizar {
  background-position: center center;
  background-size: cover;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imagen-cotizar h2 {
  font-size: 4rem;
  font-weight: 900;
  color: #FFFFFF;
}
.imagen-cotizar p {
  font-size: 1.8rem;
  color: #FFFFFF;
}

.escribenos {
  border: 1px solid #3D8C03;
}

.mensaje {
  display: grid;
  flex-direction: column;
}
@media (min-width: 768px) {
  .mensaje {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
}

.cotizar-mensaje {
  text-align: center;
}
@media (min-width: 768px) {
  .cotizar-mensaje {
    text-align: left;
  }
  .cotizar-mensaje h4 {
    margin: 0;
  }
  .cotizar-mensaje p {
    margin: 0;
  }
}

.cotizar-botones {
  text-align: center;
}
@media (min-width: 768px) {
  .cotizar-botones {
    text-align: right;
  }
}
.cotizar-botones .boton-gris {
  color: #3D8C03;
  border: 1px solid #3D8C03;
  background-color: #FFFFFF;
}

.entrada-blog {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .entrada-blog {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 8rem;
  }
}
.entrada-blog:last-of-type {
  margin-bottom: 0;
}

.texto-entrada a {
  color: #000000;
  display: block;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .texto-entrada a {
    margin-top: 0;
  }
}
.texto-entrada h4 {
  margin: 0;
  line-height: 1.4;
}
.texto-entrada h4::after {
  content: "";
  display: block;
  width: 15rem;
  height: 0.5rem;
  background-color: #3D8C03;
}
.texto-entrada span {
  color: #E08709;
}

@media (min-width: 768px) {
  .carrusel ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 100%;
    scroll-behavior: smooth;
  }
}

.carrusel-fotos {
  display: flex;
  gap: 1rem;
  overflow: auto;
}

@media (min-width: 768px) {
  .carrusel-fotos {
    overflow: auto;
  }
  .carrusel-menu {
    display: none;
  }
}
.carrusel-fotos img {
  background: #eee;
  border: 2px solid #e1e1e1;
  width: 191px;
  height: 150px;
  object-fit: contain;
}

.carrusel-menu {
  display: none;
  text-align: center;
  margin: 2rem;
}

.carrusel-menu li {
  display: inline-block;
  text-align: center;
}

.carrusel-menu li a {
  display: inline-block;
  color: white;
  text-decoration: none;
  background-color: #71B100;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  border-radius: 100%;
}

/** Media Queries **/
.galeria {
  padding-bottom: 2rem;
}
.galeria h2 {
  text-align: center;
  color: #3D8C03;
  font-size: 4rem;
  font-weight: 900;
}

.galeria-imagenes {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 480px) {
  .galeria-imagenes {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) {
  .galeria-imagenes {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1024px) {
  .galeria-imagenes {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(6, 1fr);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay picture {
  display: block;
  max-width: 80rem;
}

.btn-cerrar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #000000;
  display: grid;
  place-items: center;
  font-weight: 700;
}
.btn-cerrar:hover {
  cursor: pointer;
}

.no-webp .imagen-contacto {
  background-image: url("../img/encuentra.jpg");
}

.webp .imagen-contacto {
  background-image: url("../img/encuentra.webp");
}

.imagen-contacto {
  background-position: center center;
  background-size: cover;
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imagen-contacto h2 {
  font-size: 4rem;
  font-weight: 900;
  color: #FFFFFF;
}
.imagen-contacto p {
  font-size: 1.8rem;
  color: #FFFFFF;
}

.formulario p {
  font-size: 1.4rem;
  color: #333333;
  margin: 2rem 0 0 0;
}
.formulario legend {
  font-size: 2rem;
  color: #333333;
}
.formulario label {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
.formulario input:not([type=submit]),
.formulario textarea,
.formulario select {
  padding: 1rem;
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.formulario textarea {
  height: 20rem;
}
.formulario .forma-contacto {
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formulario input[type=radio] {
  margin: 0;
  width: auto;
}
.formulario .checkbox {
  align-items: start;
}
.formulario .check {
  /*@include tablet{
      @include grid(2,1fr);
  }
  */
  max-width: 41rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formulario input[type=checkbox] {
  margin: 0;
  padding-top: 2rem;
  width: auto;
}