@include tablet{
    .contenedor-servicios{
        @include grid(4, 2rem);
        
    }
}
.servicio{
    background-color: lighten($gris,5);
    border: 1px solid $gris;
    .contenido-servicio{
        padding: 2rem;
        text-align: center;
        @include tablet{
            text-align: center;
        }
        h5, p{
        margin: 0;
        }
        .amb{
            margin: 0;
            text-align: center;
            @include tablet{
                margin: 0;
                max-width: 10rem;
                line-height: 3.3rem;
                text-align: center;

            }
        }
    }    
}
.servicio-detalle{
    text-align: left;
    background-color: lighten($gris,5);
    border: 1px solid $gris;
    .contenido-servicio{
        padding: 2rem;
    }
    .contenido-servicio li:before{content: "- "}
    .contenido-servicio li{
        text-indent: -10px;
    }
    
    
}
.seccion{
    h2{
        font-size: 4rem;
        font-weight: $black;
        color: $verdePrincipal
    }
}
