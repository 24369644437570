.derecha{
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        align-items: end;
    }
    .dark-mode-boton{
        width: 3rem;
        margin-top: 3rem;
        margin-bottom: 2rem;
        filter: invert(100%);
        &:hover {
            cursor: pointer;
        }
        @include tablet{
            margin-bottom: 0;
        }
    }
}
.barra{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    align-items: center;
    background-color: $verdePrincipal;
    @include tablet {
        padding-top: 0;
        padding-bottom: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
    }
}
.navegacion{
    display: none;
    //visibility: hidden;
    //opacity: 0;
    //height: 0;
    //transition: visibility 3s ease-in-out, opacity .5s linear, height .5s linear;
    transition: visibility 3s ease-in-out;

    @include tablet{
        display: flex;
        align-items: center;
        //opacity: 1;
        //visibility: visible;
    }
    a {
        color: $blanco;
        text-decoration: none;
        font-size: 2.2rem;
        display: block;
        text-align: center;
        
        @include tablet{
            margin-right: 2rem;
            font-size: 1.8rem;
            &:last-of-type{
                margin-right: 0;
            }
        }
    }
}
/*.mobile-menu{
    @include tablet{
        display: none;
    }
}*/
.mobileMenu{
    @include tablet{
        display: none;
    }
    img {
        width: 5rem;
    }
}
.mostrar{
    //visibility: visible;
    
    display: flex;
    flex-direction: column;
    @include tablet{
        flex-direction: row;
    }
}