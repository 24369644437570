@use 'base/variables' as v;
@use 'base/mixins' as m;

.galeria {
    //padding: 5rem 0;
    h2 {
        text-align: center;
        color: $verdePrincipal;
        font-size: 4rem;
        font-weight: 900;
    }
    padding-bottom: 2rem;
}

.galeria-imagenes {
    @include resetear-lista;
    @include grid(2, 2rem);
    @include telefono {
        @include grid(3, 2rem);
    }
    @include tablet {
        @include grid(4, 2rem);
    }
    @include desktop {
        @include grid(6, 2rem);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;

    background-color: rgb(0 0 0 / .5);

    picture {
        display: block;
        max-width: 80rem;
    }
}

.btn-cerrar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $blanco;
    color: $negro;
    display: grid;
    place-items: center;
    font-weight: 700;

    &:hover {
        cursor: pointer;
    }
}