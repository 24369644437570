.carrusel ul{
	@include tablet{
		list-style: none;	
		margin: 0;
		padding: 0;
		max-width: 100%;
		scroll-behavior: smooth;	
	}
}
.carrusel-fotos{
	display: flex;
	gap: 1rem;
	overflow: auto;
}
@include tablet{
	.carrusel-fotos{
	overflow: auto;
	}
	.carrusel-menu{
		display: none;
	}
}
.carrusel-fotos img{
	background: $plomo;
	border: 2px solid $plomoBorde;
	width: 191px;
	height: 150px;
	object-fit:contain;
}
.carrusel-menu{
	display: none;
	text-align: center;
	margin: 2rem;
}
.carrusel-menu li{
	display:inline-block;
	text-align: center;
}
.carrusel-menu li a{
	display: inline-block;
	color: white;
	text-decoration: none;
	background-color: $verde;
	padding: 1rem;
	width: 2rem;
	height: 2rem;
	font-size: 2rem;
	border-radius: 100%;
}
