.seccion {
    margin-top: $separacion / 2;
    margin-bottom: $separacion / 2;
}
.contenido-centrado {
    max-width: 80rem;
}

.alinear-derecha {
    display: flex;
    justify-content: flex-end;
}